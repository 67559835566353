a {
    text-decoration: none;
    color: #1F1F1F;
    cursor: pointer;
}
@blue: #0d5bb6;
.wrapper {
    .wrapper();
}

.debugGrid {
    z-index: -1;
    .debug(rgba(95, 1, 1, 0.05), 1px solid rgba(255, 255, 0, 0));
}

a,
span,
p,
h1,
h2,
h3,
li,
div {
    margin: 0;
}