body {
  font-family: "Montserrat", sans-serif;
  color: #1f1f1f;
}

.rotate {
  transform: rotate(180deg);
}
.btn-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 127.7%;
  color: #0d5bb6;
  border: 2px solid #0d5bb6;
  box-sizing: border-box;
  border-radius: 27px;
  padding: 17px 37px;
}
.btn-fill {
  font-weight: bold;
  font-size: 15px;
  line-height: 145.7%;
  text-transform: uppercase;
  color: #ffffff;
  background: #0d5bb6;
  box-shadow: 0px 4px 10px rgba(13, 91, 182, 0.27);
  border-radius: 28px;
  padding: 18px 50px;
  display: inline-block;
}
img {
  max-width: 100%;
}
.metroPopup,
.areaPopup,
.metroPopupF,
.areaPopupF {
  display: none;
  padding: 30px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  top: 80px;
  .xxs(top, 150px);
  right: -1px;
  min-width: 210px;
  .wrap {
    display: flex;
    flex-wrap: wrap;
  }
  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 127.7%;
    color: #000000;
    margin-bottom: 15px;
  }
  ul {
    margin: 0;
    padding: 0;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    li {
      list-style-type: none;
      font-weight: normal;
      font-size: 13px;
      line-height: 127.7%;
      text-decoration-line: underline;
      color: #0d5bb6;
      margin-bottom: 10px;
      white-space: nowrap;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.metroPopup:before {
  position: absolute;
  content: url(../img/popup-shape.svg);
  top: -14px;
  right: 22%;
}
.metroPopupF:before {
  position: absolute;
  content: url(../img/popup-shape.svg);
  bottom: -13px;
  transform: rotate(180deg);
  right: 22%;
}
.areaPopup:before {
  position: absolute;
  content: url(../img/popup-shape.svg);
  top: -14px;
  left: 22%;
}
.areaPopupF:before {
  position: absolute;
  content: url(../img/popup-shape.svg);
  bottom: -13px;
  transform: rotate(180deg);
  left: 22%;
}

.header {
  > .wrapper {
    background: #f9f9f9 url(../img/header-bg.png) no-repeat left -45% top;
    .sm(background, #f9f9f9);
  }
  background: linear-gradient(to right, #f5f5f5 50%, #f9f9f9 50%);
  .top {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 35px 0;
    .xxs(padding-bottom, 10px);
    .md(flex-direction, column);
    .logo {
      display: flex;
      align-items: center;
      a {
        margin-right: 16px;
      }
      p {
        font-weight: 900;
        font-size: 22px;
        line-height: 27px;
        color: #000000;
      }
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 17px;
        color: #000000;
      }
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      position: relative;
      > a {
        font-weight: bold;
        font-size: 14px;
        line-height: 127.7%;
        color: #0d5bb6;
        border: 2px solid #0d5bb6;
        box-sizing: border-box;
        border-radius: 27px;
        padding: 17px 37px;
        margin: 0 13px;
        height: 55px;
        .md(margin-top, 10px);
        .md(margin-bottom, 10px);
        .metro {
          margin-bottom: -2px;
        }
        &:hover {
          background-color: @blue;
          color: #fff;
          & svg path {
            fill: white;
          }
        }
      }
    }
    .num {
      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 127.7%;
        color: #828282;
        .xxs-block({text-align: center; display: block;});
      }
      .wrap {
        align-items: center;
        display: flex;
        margin-top: 6px;
        span {
          margin-left: 11px;
          font-weight: bold;
          font-size: 23px;
          line-height: 127.7%;
          color: #000000;
        }
      }
    }
  }
  .center {
    padding-top: 65px;
    .md(padding-top, 0);
    padding-bottom: 27px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    .text {
      width: 55%;
      .md(width, 50%);
      .xsm(width, 100%);
      .xsm-block({text-align: center;});
      p {
        font-weight: bold;
        font-size: 16px;
        line-height: 145.7%;
        text-transform: uppercase;
        color: #0d5bb6;
      }
      h1 {
        font-weight: 900;
        font-size: 40px;
        .xs(font-size, 25px);
        line-height: 127.7%;
        color: #1f1f1f;
        .md(margin, 0 auto);
        max-width: 600px;
        margin-bottom: 15px;
        max-width: 600px;
      }
      span {
        font-weight: normal;
        font-size: 15px;
        line-height: 150.2%;
        color: #1f1f1f;
      }
      a {
        margin-top: 27px;
        box-shadow: 0px 4px 10px rgba(13, 91, 182, 0.27);
      }
    }
    .img {
      width: 45%;
      .md(width, 50%);
      .xsm(width, 100%);
      .xsm(margin-top, 30px);
      .xsm(text-align, center);
    }
  }
  .bottom {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 34px;
    .md(justify-content, center);
    .wrapp {
      display: flex;
      .item:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-right: 10px;
      }
    }
    .item {
      overflow: hidden;
      display: flex;
      width: 280px;
      .size-md(6);
      .md(margin, 10px);
      .size-xs(10);
      .size-xxs(12);
      background: #ffffff;
      margin-right: 10px;
      .xs(margin, 0 0 10px 0);
      margin-bottom: 10px;
      cursor: pointer;
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.05);
      justify-content: space-between;
      .text {
        margin-top: 26px;
        margin-bottom: 37px;
        margin-left: 32px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        min-width: 40%;
        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 140.2%;
          color: #000000;
        }
        span {
          font-size: 16px;
          line-height: 140.2%;
          color: #000000;
          > span {
            color: @blue;
            font-weight: bold;
            font-size: 20px;
          }
        }
      }
      > img {
        margin: 15px 0;
        height: 140px;
      }
      &:hover {
        background: linear-gradient(90deg, #0d5bb6 1.75%, #3277cc 100%);
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
        & .text h3,
        & .text span {
          color: white;
        }
        & svg path {
          fill: white;
        }
      }
    }
  }
}
.options {
  background: linear-gradient(270deg, #3277cc 0%, #0d5bb6 99.76%);
  padding: 34px 0;
  .items {
    display: flex;
    justify-content: space-between;
    .sm(justify-content, space-around);
    flex-wrap: wrap;
    .item {
      display: flex;
      align-items: center;
      margin: 15px 0;
      min-width: 26%;
      width: 310px;
      .image {
        min-width: 74px;
        text-align: center;
      }
      h3 {
        font-size: 16px;
      }
      .text {
        margin-left: 12px;
        font-weight: bold;
        font-size: 16px;
        line-height: 140.2%;
        color: #ffffff;
        span {
          font-weight: normal;
          font-size: 16px;
          line-height: 140.2%;
          color: #ffffff;
          display: inline-block;
          margin-top: 4px;
          img {
            margin-bottom: -6px;
          }
        }
      }
    }
  }
}
.call {
  background: #f9f9f9;
  .title {
    text-align: center;
    padding-top: 65px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      color: #000000;
      margin-bottom: 6px;
    }
    span {
      margin-bottom: 15px;
      display: inline-block;
      max-width: 345px;
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      color: #4a4a4a;
    }
    .img {
      font-size: 0;
    }
  }
  .items {
    display: flex;
    justify-content: space-between;
    .md(justify-content, space-around);
    flex-wrap: wrap;
    padding-top: 51px;
    padding-bottom: 79px;
    .item {
      width: 270px;
      min-width: 270px;
      background: #ffffff;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      align-items: center;
      .size-md(4.5);
      .size-xs(12);
      .md(margin-bottom, 30px);
      .img {
        height: 300px;
        margin-top: 20px;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
      .desc {
        padding: 12px;
        background: #0d5bb6;
        text-align: center;
        width: 100%;
        box-sizing: border-box;
        span {
          color: #ffffff;
          font-weight: bold;
          font-size: 16px;
          line-height: 140.2%;
        }
        .span2 {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 6px;
          display: block;
        }
        .span3 {
          font-size: 14px;
        }
      }
    }
  }
}
.prices {
  padding-top: 51px;
  padding-bottom: 43px;
  background: #ffffff;
  .title {
    text-align: center;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      color: #000000;
      margin-bottom: 6px;
    }
    span {
      margin-bottom: 15px;
      display: inline-block;
      max-width: 410px;
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      color: #4a4a4a;
    }
    .img {
      font-size: 0;
    }
  }
  .items {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      width: 370px;
      .xxxs(width, 100%);
      margin-right: 30px;
      margin-bottom: 30px;
      .md(margin-right, 0);
      &:last-child {
        margin-right: 0px;
      }
      .name {
        background: @blue;
        display: flex;
        align-items: center;
        padding: 10px 15px;
        span {
          display: block;
          font-weight: bold;
          font-size: 15px;
          line-height: 140.2%;
          color: #ffffff;
          margin-left: 10px;
        }
      }
      .option {
        border: 1px solid #e9e9e9;
        background: #f2f2f2;
        span {
          display: inline-block;
          font-weight: normal;
          font-size: 14px;
          line-height: 140.2%;
          color: #000000;
          padding: 10px 11px;
          .xxxs(padding, 3px);
        }
        span:first-child {
          border-right: 1px solid #e9e9e9;
          width: 278px;
          .xxxs(width, 75%);
          box-sizing: border-box;
        }
        span:last-child {
          width: 90px;
          .xxxs(width, 25%);
          box-sizing: border-box;
        }
      }
      .option:nth-child(2n) {
        background: #ffffff;
      }
    }
  }
  .items-cat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .md(justify-content, space-around);
    align-items: center;
    .item {
      width: 370px;
      max-width: 370px;
      .xxs(width, 100%);
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      &:last-child {
        margin-right: 0px;
      }
      .text {
        margin-left: 44px;
        margin-top: 26px;
        max-width: 190px;
        > .blue {
          color: @blue;
          &:before {
            display: none;
          }
        }
        div {
          font-weight: bold;
          font-size: 15px;
          line-height: 140.2%;
          color: #000000;
          position: relative;
          margin-bottom: 15px;
          &:before {
            content: "";
            width: 8px;
            height: 8px;
            background: #0d5bb6;
            position: absolute;
            transform: translateY(-40%);
            top: 50%;
            left: -21px;
          }
        }
      }
      .img {
        height: 154px;
        margin: 20px 0;
        min-width: 33%;
        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
.notFind {
  background: linear-gradient(270deg, #3277cc 0%, #0d5bb6 99.76%);
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    padding: 35px 15px;
    .md(justify-content, space-around);
    .text {
      max-width: 520px;
      margin-left: 180px;
      .md(margin, 0);
      .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 140.2%;
        color: #ffffff;
        margin-bottom: 10px;
        .sm(text-align, center);
      }
      .span {
        font-weight: normal;
        font-size: 15px;
        line-height: 140.2%;
        color: #ffffff;
        .sm(text-align, center);
      }
    }
    img {
      position: absolute;
      bottom: 0;
      .md(display, none);
      &:last-child {
        right: 0;
      }
      &:first-child {
        left: 0;
      }
    }
    .button {
      padding: 20px 0;
      .xsm(padding-bottom, 0px);
      a {
        background-color: #fff;
        color: @blue;
      }
    }
  }
}
.repair {
  background: #f9f9f9;
  .title {
    text-align: center;
    margin-bottom: 24px;
    padding-top: 45px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      color: #000000;
      margin-bottom: 6px;
    }
    img {
      font-size: 0;
    }
  }
  .wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 57px;
    justify-content: center;
    .text {
      max-width: 50%;
      width: 530px;
      .xsm(max-width, 100%);
      &__title {
        font-weight: bold;
        font-size: 16px;
        line-height: 150.2%;
        text-align: left;
        color: #000000;
        margin-bottom: 5px;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 161.2%;
        color: #1f1f1f;
        display: inline-block;
      }
      .item {
        margin-top: 15px;
        margin-right: 50px;
        align-items: center;
        min-width: 150px;
        display: inline-block;
        span {
          font-weight: bold;
          font-size: 14px;
          line-height: 161.2%;
          color: #000000;
        }
        .img {
          width: 30px;
          display: inline-block;
          text-align: center;
          margin-right: 4px;
        }
        img {
          margin-bottom: -4px;
          height: 20px;
        }
      }
    }
    > .img {
      width: 50%;
      .xsm(width, 100%);
    }
  }
}
.brands {
  .title {
    text-align: center;
    padding-bottom: 24px;
    padding-top: 45px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      color: #000000;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      text-align: center;
      margin-bottom: 15px;
      color: #4a4a4a;
      display: block;
    }
    img {
      font-size: 0px;
      display: block;
      margin: 0 auto;
    }
  }
  .items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .img {
      .size(12/5);
      .size-sm(12/3);
      .size-xxs(12/2);
      margin: 0 15px;
      margin-bottom: 20px;
      height: 96px;
      text-align: center;
      img {
        object-fit: contain;
        width: 183px;
        height: 96px;
      }
    }
    .dop {
      display: none;
    }
  }
  .wrap {
    text-align: center;
    padding-bottom: 80px;
  }
}
.often {
  background: #f9f9f9;
  padding-bottom: 65px;
  .title {
    text-align: center;
    margin-bottom: 48px;
    padding-top: 45px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      color: #000000;
      margin-bottom: 6px;
    }
    img {
      font-size: 0;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      background: #ffffff;
      border: 1px solid #f7f7f7;
      box-sizing: border-box;
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.05);
      width: 270px;
      .size-md(6);
      .size-xs(12);
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 30px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      &:after {
        content: "";
        width: 40px;
        height: 5px;
        background-color: @blue;
        position: absolute;
        top: 0;
        left: 24px;
      }
      p {
        margin: 20px 5px 20px 23px;
        font-weight: normal;
        font-size: 16px;
        line-height: 130.7%;
        color: #333333;
      }
      span {
        margin-right: 15px;
        font-weight: 800;
        font-size: 33px;
        line-height: 127.7%;
        text-align: center;
        color: #e7e7e7;
      }
    }
  }
  .wrap {
    text-align: center;
    a {
      display: inline-block;
      margin-top: 10px;
      font-weight: bold;
      font-size: 14px;
      line-height: 127.7%;
      background-color: @blue;
      color: #ffffff;
    }
  }
}
.stage {
  background: linear-gradient(270deg, #3277cc 0%, #0d5bb6 99.76%);
  padding-bottom: 30px;
  .title {
    text-align: center;
    margin-bottom: 50px;
    padding-top: 53px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      text-align: center;
      color: #ffffff;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      text-align: center;
      color: #ffffff;
      display: block;
      margin-bottom: 15px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .items {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .item {
      width: 270px;
      text-align: center;
      margin-right: 105px;
      margin-bottom: 50px;
      position: relative;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .md(margin-right, 0);
      .img {
        height: 45px;
      }
      p {
        font-weight: bold;
        font-size: 18px;
        line-height: 140.2%;
        color: #ffffff;
        margin-bottom: 3px;
        span {
          transform: translate(13%, 8%);
          display: inline-block;
          font-weight: 900;
          font-size: 27px;
          line-height: 144.2%;
          color: rgba(255, 255, 255, 0.16);
        }
      }
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 144.2%;
        text-align: center;
        color: #ffffff;
      }
      .pointer1:after {
        position: absolute;
        right: -55%;
        top: 60%;
        content: url(../img/stage-dec1.svg);
        .md(display, none);
      }
      .pointer2:after {
        position: absolute;
        right: -58%;
        top: 42%;
        content: url(../img/stage-dec2.svg);
        .md(display, none);
      }
      .pointer3:after {
        position: absolute;
        bottom: -100%;
        content: url(../img/stage-dec3.svg);
        .md(display, none);
      }
      .pointer4:after {
        position: absolute;
        top: 38%;
        right: -70%;
        content: url(../img/stage-dec4.svg);
        .md(display, none);
      }
      .pointer5:after {
        position: absolute;
        top: 40%;
        right: -66%;
        content: url(../img/stage-dec5.svg);
        .md(display, none);
      }
    }
  }
  .wrap {
    text-align: center;
    a {
      display: inline-block;
      margin-top: 10px;
    }
  }
}
.pay {
  padding-bottom: 108px;
  .xs(padding-bottom, 50px);
  background: #f9f9f9;
  .title {
    text-align: center;
    margin-bottom: 50px;
    padding-top: 53px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      text-align: center;
      color: #000000;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      text-align: center;
      color: #4a4a4a;
      display: block;
      margin-bottom: 15px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .xsm(align-items, center);
    .xsm(flex-direction, column);
    .item {
      max-width: 220px;
      .xsm(max-width, 350px);
      span.ic {
        font-weight: bold;
        font-size: 50px;
        line-height: 140.2%;
        color: #1964bd;
      }
      p {
        font-weight: bold;
        font-size: 18px;
        line-height: 140.2%;
        color: #000000;
        margin-bottom: 7px;
      }
      span {
        font-weight: normal;
        font-size: 14px;
        line-height: 144.2%;
        color: #000000;
      }
    }
  }
}
.video {
  .title {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 53px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      text-align: center;
      color: #000000;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      text-align: center;
      color: #4a4a4a;
      display: block;
      margin-bottom: 15px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .slider {
    max-width: 800px;
    margin: 0 auto;
    .sm(max-width, calc(100% - 30px));
  }
  .wrap {
    text-align: center;
    position: relative;
    > a {
      display: inline-block;
      margin-top: 35px;
      margin-bottom: 74px;
    }
  }
  iframe {
    border: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
  }
}
.support {
  background: linear-gradient(270deg, #3277cc 0%, #0d5bb6 99.76%);
  padding-top: 106px;
  .xsm(padding-top, 50px);
  .wrapper {
    display: flex;
    .img {
      width: 50%;
      .xsm(display, none);
      position: relative;
      margin-right: 34px;
      justify-content: center;
      text-align: right;
      img {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    .text {
      margin-left: 34px;
      .xsm(margin-left, 10px);
      width: 50%;
      .xsm(width, 100%);
      .xsm(text-align, center);
      padding-bottom: 50px;
      div {
        h2 {
          font-weight: 900;
          font-size: 21px;
          line-height: 112.7%;
        }
        font-weight: 900;
        font-size: 21px;
        line-height: 112.7%;
        color: #ffffff;
        position: relative;
        margin-bottom: 15px;
        .xsm(margin-bottom, 0);
        p {
          font-size: 36px;
          line-height: 1.4;
        }
        span {
          position: absolute;
          top: -85px;
          .md(top, -50px);
          .xsm(left, 125px);
          left: -5px;
          font-weight: 900;
          font-size: 106px;
          .md(font-size, 70px);
          .xsm(font-size, 0px);
          line-height: 127.7%;
          letter-spacing: 0.16em;
          color: rgba(255, 255, 255, 0.08);
        }
      }
      span {
        font-weight: normal;
        font-size: 15px;
        line-height: 150.2%;
        color: #ffffff;
      }
      a {
        margin-top: 35px;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(13, 91, 182, 0.27);
        color: #2b72c8;
      }
    }
  }
}
.about {
  .title {
    text-align: center;
    margin-bottom: 40px;
    padding-top: 53px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      text-align: center;
      color: #000000;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      text-align: center;
      color: #4a4a4a;
      display: block;
      margin-bottom: 15px;
      max-width: 370px;
      margin: 0 auto 13px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .text {
    padding-bottom: 60px;
    p {
      font-weight: normal;
      font-size: 15px;
      line-height: 150.7%;
      color: #1f1f1f;
    }
  }
}
.service {
  padding-bottom: 82px;
  background: url(../img/service-bg2.png) no-repeat;
  background-color: rgba(13, 91, 182, 0.79);
  background-size: cover;
  .title {
    text-align: center;
    margin-bottom: 55px;
    padding-top: 53px;
    h2 {
      font-weight: 800;
      font-size: 25px;
      line-height: 140.2%;
      text-align: center;
      color: #ffffff;
      margin-bottom: 6px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      text-align: center;
      color: #ffffff;
      display: block;
      max-width: 370px;
      margin: 0 auto 15px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .items {
    padding-bottom: 14px;
    ul {
      display: flex;
      justify-content: space-between;
      .md(justify-content, space-around);
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      margin-left: 26px;
      li {
        list-style-type: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 127.7%;
        text-decoration-line: underline;
        color: #ffffff;
        position: relative;
        margin: 0;
        padding: 0;
        margin-right: 25px;
        margin-bottom: 36px;
        min-width: 220px;
        &:after {
          content: url(../img/map-point.svg);
          position: absolute;
          left: -30px;
          top: -6px;
        }
      }
    }
  }
  .wrap {
    text-align: center;
    a {
      background: #fff;
      color: @blue;
      font-weight: bold;
      font-size: 14px;
      line-height: 127.7%;
      text-transform: none;
      color: #0d5bb6;
    }
  }
}
.map {
  position: relative;
  height: 550px;
  iframe,img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 550px;
    background-color: lightgrey;
  }
  .poster {
    position: absolute;
    top: 10%;
    left: 10%;
    .xxs(left, 0);
    padding-left: 30px;
    padding-top: 30px;
    padding-right: 70px;
    .xxs(padding-right, 30px);
    padding-bottom: 30px;
    background: #ffffff;
    box-shadow: 0px 4px 28px rgba(0, 0, 0, 0.19);
    h3 {
      font-weight: 900;
      font-size: 20px;
      line-height: 127.7%;
      margin-bottom: 13px;
      color: #000000;
    }
    .ad {
      font-weight: normal;
      font-size: 14px;
      line-height: 145.2%;
      display: block;
      color: #000000;
      margin-left: 25px;
      margin-bottom: 25px;
      position: relative;
      &:before {
        content: url(../img/geo.svg);
        position: absolute;
        left: -25px;
      }
    }
    .save {
      font-weight: bold;
      font-size: 14px;
      line-height: 127.7%;
      margin-left: 25px;
      color: #0d5bb6;
      position: relative;
      &:before {
        content: url(../img/mail.svg);
        position: absolute;
        left: -25px;
      }
    }
  }
}
.footer {
  background: #183c67;
  .wrapper {
    .top {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 35px 0 47px;
      .md(flex-direction, column);
      .logo {
        display: flex;
        align-items: center;
        a {
          margin-right: 16px;
        }
        p {
          font-weight: 900;
          font-size: 22px;
          line-height: 27px;

          color: #ffffff;
        }
        h3 {
          font-weight: 500;
          font-size: 13px;
          line-height: 17px;

          color: #ffffff;
        }
      }
      .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        > a {
          margin: 0 13px;
          .md(margin-top, 10px);
          .md(margin-bottom, 10px);
          .metro {
            margin-bottom: -2px;
          }
          border: 2px solid #ffffff;
          color: #fff;
          img {
            margin-left: 3px;
          }
        }
        .metroPopupF,
        .areaPopupF {
          bottom: 150%;
          right: 14px;
          top: auto;
        }
      }
      .num {
        span {
          font-weight: 500;
          font-size: 13px;
          line-height: 127.7%;
          color: #ffffff;
        }
        .wrap {
          align-items: center;
          display: flex;
          margin-top: 6px;
          a {
            margin-left: 11px;
            font-weight: bold;
            font-size: 23px;
            line-height: 127.7%;
            color: #ffffff;
          }
        }
      }
    }
    .last {
      display: flex;
      justify-content: flex-end;
      .xxs(flex-direction, column);
      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 127.7%;
        text-align: center;
        color: #ffffff;
        padding-bottom: 30px;
        .xxs(padding-bottom, 15px);
        &:first-child {
          margin-right: 190px;
          .xxs(margin-right, 0);
        }
      }
    }
  }
}
.cPopup,
.rPopup,
.thank {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  display: none;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: 1000;
  &:target {
    display: block;
    -webkit-animation: fade 0.6s;
    -moz-animation: fade 0.6s;
    animation: fade 0.6s;
  }
  .dm-table {
    display: table;
    width: 100%;
    height: 100%;
  }
  .dm-cell {
    display: table-cell;
    padding: 0 2em;
    margin: 22px 0;
    vertical-align: middle;
    text-align: center;
  }
  .dm-modal {
    margin-top: 25px;
    display: inline-block;
    position: relative;
    background-color: #fff;
    max-width: 410px;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    padding: 30px 40px;
    .xxs(padding, 15px 20px);
    box-sizing: border-box;
    &:before {
      content: "";
      width: 60px;
      height: 5px;
      background-color: @blue;
      position: absolute;
      top: 0;
      left: 40px;
    }
    p {
      font-weight: 900;
      font-size: 23px;
      line-height: 127.7%;
      color: #000000;
      margin-bottom: 10px;
      text-align: left;
    }
    > span {
      font-weight: normal;
      font-size: 14px;
      line-height: 140.2%;
      color: #4b4b4b;
      margin-bottom: 20px;
      display: block;
      text-align: left;
    }
    form {
      text-align: left;
      span {
        font-weight: bold;
        font-size: 13px;
        line-height: 127.7%;
        color: #060606;
        margin-bottom: 6px;
        display: block;
      }
      input[name="text"] {
        background: #f5f5f5;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 29px;
        font-weight: normal;
        font-size: 14px;
        line-height: 127.7%;
        color: #a7a7a7;
        width: 100%;
        padding: 18px 30px;
        outline: none;
        margin-bottom: 20px;
      }
      textarea,
      input[name="textarea"] {
        resize: none;
        background: #f5f5f5;
        border: 1px solid #e9e9e9;
        box-sizing: border-box;
        border-radius: 29px;
        font-weight: normal;
        font-size: 14px;
        line-height: 127.7%;
        color: #a7a7a7;
        width: 100%;
        padding: 18px 30px;
        outline: none;
        margin-bottom: 13px;
      }
      input[type="submit"],
      .submit {
        margin-top: 21px;
        margin-bottom: 10px;
        outline: none;
        border: 0;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
      }
      .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      .inputfile + label {
        font-weight: bold;
        font-size: 14px;
        line-height: 145.7%;
        text-transform: uppercase;
        cursor: pointer;
        color: #0d5bb6;
        img {
          margin-bottom: -6px;
        }
      }
    }
    span.att {
      font-size: 12px;
      line-height: 138.19%;
      color: #737373;
      margin-bottom: 0;
      a {
        color: @blue;
      }
    }
    .submit {
      margin-bottom: 15px;
      margin-top: 20px;
      cursor: pointer;
      .xxxs(padding, 18px);
    }
    form input[type="tel"] {
      margin-bottom: 0;
    }
  }
  .close {
    z-index: 9999;
    width: 22px;
    height: 22px;
    color: #cfd8dc;
    text-align: center;
    text-decoration: none;
    line-height: 26px;
    cursor: pointer;
    position: absolute;
    right: -22px;
    top: -22px;
    &:after {
      display: block;
      content: url(../img/close.svg);
      transition: all 0.6s;
    }
    &:hover:after {
      border-color: #fff;
      color: #fff;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }
  }
}

.rPopup {
  form {
    textarea,
    input[name="textarea"] {
      height: 100px;
    }
    input[type="submit"] {
      .xxs(padding, 18px);
      cursor: pointer;
    }
  }
}
.thank {
  span {
    margin-bottom: 0 !important;
  }
}
